@import url('https://fonts.googleapis.com/css?family=Patrick+Hand|Noto+Sans+JP:400|Noto+Sans+JP:500|Noto+Sans+JP:700&display=swap');

$body-bg: white;
$primary: #1A284B;
$secondary: #269AF4;
$tertiary: #FFC154;
$body-color: #6F819A;
$quaternary: #6F819A;
$quinary: #E7E9ED;
$senary: #EDF8FF;
$price-color: #38a390;

$headings-color: $primary;
$search-home-bg-color: $tertiary;
$search-overview-bg-color: $tertiary;
$header-bg-color: white;
$navbar-dark-color: white;
$navbar-dark-hover-color: white;
$mobile-header-color: $primary;
$mobile-header-background: white;
$footer-background-color: #F9F9F9;
$bottom-footer-bg-color: #F9F9F9;
$footer-text-color: $body-color;
$footer-border-top: 1px solid #ddd;
$footer-text-color-header: $tertiary;

$static-header_area-form_text_color: $primary;
$static-header_area_tips_background_color: rgba($senary, .8);

$base-font: 'Noto Sans JP', Arial, Helvetica, "Nimbus Sans L", sans-serif;
$body-font: $base-font;
$navbar-font-family: $base-font;
$headings-font-family: 'Patrick Hand', Arial, Helvetica, "Nimbus Sans L", sans-serif;
$font-family-sans-serif: $base-font;
$font-family-base: $base-font;
$btn-font-family: $base-font;
$body-font-family: $base-font;
$body-font-size: .95rem;

@import "../../flean_base_2019/sass/imports";

h1 {
  font-size: 2rem;
}

body {
  line-height: 2;
}

.price-info .price-values {
  color: $price-color;
}

h2, h3, h4, h5, h6 {
  color: $primary;
}

.column h3,
.card-title {
  font-family: $body-font;
}

.front .paragraphs-items .field-paragraphs:nth-child(even),
.not-front .paragraphs-items .field-paragraphs:nth-child(odd),
.alternate {
  background: white;
  box-shadow: none;
}

.static-header .container .search-form {
  input[type="submit"] {
    font-weight: 500 !important;
    font-size: 1rem;
    padding: .75rem !important;
  }

  .subtitle {
    line-height: 1.5;
    font-size: 1em;
  }
}

.static-header .container .static-area {
  font-size: 1rem;
}

.static-header .container .static-area h3 {
  color: $primary !important;
  font-family: $body-font;
  font-weight: bold;
  font-size: 1.2rem;
}

#header > .header .header-bottom {
  background: $secondary;

  a {
    font-size: 1.1rem;
    font-weight: 500;
    margin-right: 2rem;
  }
}

.btn.btn-outline-dark,
.btn.btn-outline-primary {
  color: $secondary;
  font-weight: 500;
  font-size: .8rem;
  border-color: $secondary;
  padding: .4rem .8rem;

  &:hover {
    background: none;
  }
}

.paragraphs-header h2 {
  font-size: 2rem;
}


.holiday-home-teaser a .card {
  box-shadow: none;
  padding: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: -1rem;
  margin-bottom: 1rem;

  &:hover {
    box-shadow: none;
  }

  .card-img-top img {
    border-radius: $border-radius;
  }

  .card-body {
    padding: 0;

    h4 {
      font-size: 1rem;
      margin-top: .6rem;
      margin-bottom: 0;
    }

    .accommodation-type {
      font-size: .8rem;
      color: $gray-400;
    }

    .price-info {
      display: block;

      .price-values {
        margin-top: 1rem;
        font-size: 1rem;

        .prefix {
          display: inline;
        }
      }
    }
  }
}

.static-header {
  padding-bottom: 6rem;


  .container .static-area {
    background: rgba(white, .7) !important;
    text-shadow: rgba(white, .4) 0 0 .8rem;
    color:#333;

    h3 {
      font-weight: bold !important;
    }

    @include media-breakpoint-down(sm) {
      //   background: white !important;
      margin: 1rem !important;
      width: calc(100% - 2rem);
    }
  }

  > svg {
    fill: $body-bg;
    position: absolute;
    bottom: 0;
  }
}

.search-wrapper {
  background: $primary;
}

#footer {
  background: $senary;
  border: none;

  h2, h3, h4 {
    color: $secondary;
    font-family: $body-font;
    font-size: 1rem;
  }
}
